<template>
  <div class="archive-item archive-cmn__row">
    <div class="archive-cmn__cell">
      <lazy-ava-img
        class="archive-item__ava"
        :alt="fullName"
        :title="fullName"
        :src="item.avatarLink"
      />

      <button
        class="archive-item__open-lead-btn"
        @click="isOverviewShown = true"
      >
        <span
          class="archive-cmn__str archive-cmn__str_nb"
          :title="$t('OPEN_LEAD_HINT')"
        >
          {{ fullName }}
        </span>
      </button>

      <template v-if="isOverviewShown">
        <lead-overview
          class="archive-item__lead-overview"
          :lead-id="item.leadId"
          @close="isOverviewShown = false"
        />
      </template>
    </div>

    <div class="archive-cmn__cell">
      <span
        class="archive-cmn__str archive-cmn__str_nb"
        :title="archivedBy"
      >
        {{ archivedBy }}
      </span>
    </div>

    <div class="archive-cmn__cell">
      <span class="archive-cmn__str archive-cmn__str_sec">
        {{ formatDateTimeRelative(item.createdAt) }}
      </span>
    </div>

    <div class="archive-cmn__cell">
      <span class="archive-cmn__str archive-cmn__str_sec">
        {{ archivationReasons[item.reason] }}
      </span>
    </div>

    <div class="archive-cmn__cell">
      <span class="archive-cmn__str archive-cmn__str_sec">
        {{ formatDistanceFromSeconds(item.caseDurationS) }}
      </span>
    </div>

    <div class="archive-cmn__cell">
      <span
        class="archive-cmn__str archive-item__state"
        :data-state="lastStates[item.lastState].dataAttrVal"
      >
        {{ lastStates[item.lastState].translated }}
      </span>
    </div>
  </div>
</template>

<script>
import { Archive } from 'Models/Archive'
import {
  formatDateTimeRelative,
  formatDistanceFromSeconds,
} from 'Utils/dateHelpers'
import LazyAvaImg from 'Common/LazyAvaImg'
import LeadOverview from 'Common/LeadOverview'

export default {
  name: 'archive-item',

  components: {
    LazyAvaImg,
    LeadOverview,
  },

  props: {
    item: {
      type: Archive,
      required: true,
    }
  },

  data () {
    return {
      isOverviewShown: false,
    }
  },

  computed: {
    archivationReasons () {
      return {
        [Archive.archivedReasonEnums.manual]:
          this.$t('ARCHIVED_REASON.MANUAL'),

        [Archive.archivedReasonEnums.noDeposit]:
          this.$t('ARCHIVED_REASON.NO_DEPOSIT'),

        [Archive.archivedReasonEnums.won]:
          this.$t('ARCHIVED_REASON.WON'),

        [Archive.archivedReasonEnums.identityDeleted]:
          this.$t('ARCHIVED_REASON.USER_DELETED'),
      }
    },
    lastStates () {
      return {
        [Archive.lastStatesEnum.waitingCall]: {
          dataAttrVal: 'waiting',
          translated: this.$t('WAITING_STATE'),
        },
        [Archive.lastStatesEnum.onHold]: {
          dataAttrVal: 'hold',
          translated: this.$t('HOLD_STATE'),
        },
        [Archive.lastStatesEnum.lookingCar]: {
          dataAttrVal: 'looking',
          translated: this.$t('LOOKING_STATE'),
        },
        [Archive.lastStatesEnum.offerSent]: {
          dataAttrVal: 'offered',
          translated: this.$t('OFFERED_STATE'),
        },
      }
    },

    archivedBy () {
      return this.item.archivedByFullName ||
        this.$t('ARCHIVED_AUTOMATICALLY_TXT')
    },

    fullName () {
      return this.item.fullName ||
        this.$t('COMMON.UNNAMED_USER_PH')
    }
  },

  methods: {
    formatDistanceFromSeconds,
    formatDateTimeRelative,
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/archive.scss";

.archive-item {
  padding: 2.1em 0;
  border-bottom: 1px solid rgba($color-grey, 0.5);

  &__ava {
    width: 2.2em;
    height: 2.2em;
  }

  &__open-lead-btn {
    background: none;
    border: none;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
    text-align: start;
  }

  &__state {
    &[data-state="waiting"] {
      color: $color-status-waiting;
    }

    &[data-state="hold"] {
      color: $color-status-hold;
    }

    &[data-state="looking"] {
      color: $color-status-looking;
    }

    &[data-state="offered"] {
      color: $color-status-offered;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "ARCHIVED_AUTOMATICALLY_TXT": "Automatically",
    "OPEN_LEAD_HINT": "Open lead",
    "WAITING_STATE": "Waiting for call",
    "HOLD_STATE": "On-hold",
    "LOOKING_STATE": "Looking for a car",
    "OFFERED_STATE": "Offer Sent",
    "ARCHIVED_REASON": {
      "MANUAL": "Manual",
      "NO_DEPOSIT": "No deposit",
      "WON": "Won",
      "USER_DELETED": "User Deleted"
    }
  },
  "ka": {
    "ARCHIVED_AUTOMATICALLY_TXT": "ავტომატური",
    "OPEN_LEAD_HINT": "ლიდის გახსნა",
    "WAITING_STATE": "დასარეკი",
    "HOLD_STATE": "ოდესმე",
    "LOOKING_STATE": "ძიება",
    "OFFERED_STATE": "გაგზავნილი",
    "ARCHIVED_REASON": {
      "MANUAL": "მანუალური",
      "NO_DEPOSIT": "დეპოზიტის გარეშე",
      "WON": "მოგებული",
      "USER_DELETED": "მომხმარებელი წაშლილია"
    }
  },
  "ru": {
    "ARCHIVED_AUTOMATICALLY_TXT": "Автоматически",
    "OPEN_LEAD_HINT": "Открыть лида",
    "WAITING_STATE": "В ожидании звонка",
    "HOLD_STATE": "НА УДЕРЖАНИИ",
    "LOOKING_STATE": "Поиск авто",
    "OFFERED_STATE": "Предложение отправлено",
    "ARCHIVED_REASON": {
      "MANUAL": "Вручную",
      "NO_DEPOSIT": "Нет депозита",
      "WON": "Победа",
      "USER_DELETED": "Пользователь Удален"
    }
  },
  "uk": {
    "ARCHIVED_AUTOMATICALLY_TXT": "Автоматично",
    "OPEN_LEAD_HINT": "Відкрити ліда",
    "WAITING_STATE": "В очікуванні дзвінка",
    "HOLD_STATE": "На утриманні",
    "LOOKING_STATE": "Пошук автомобіля",
    "OFFERED_STATE": "Відправлено",
    "ARCHIVED_REASON": {
      "MANUAL": "Вручну",
      "NO_DEPOSIT": "Немає депозиту",
      "WON": "Перемога",
      "USER_DELETED": "Користувача Видалено"
    }
  }
}
</i18n>
