export const archiveActions = {
  LOAD_LIST: 'LOAD_LIST',
  LOAD_MORE: 'LOAD_MORE',
  SET_IS_LOADING: 'SET_IS_LOADING',
}

export const archiveMutations = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_NEXT_LOADING: 'SET_IS_NEXT_LOADING',
  SET_LIST: 'SET_LIST',
  PUSH_LIST: 'PUSH_LIST',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  SET_FETCH_NEXT: 'SET_FETCH_NEXT',
}

export const archiveGetters = {
  IS_LOADING: 'IS_LOADING',
  IS_NEXT_LOADING: 'IS_NEXT_LOADING',
  LIST: 'LIST',
  LIST_COUNT: 'LIST_COUNT:',
  FETCH_NEXT: 'FETCH_NEXT',
}
