<template>
  <div class="archive-list-head archive-cmn__row">
    <span class="archive-list-head__cell">
      {{ $t('USER_CELL') }}
    </span>

    <span class="archive-list-head__cell">
      {{ $t('ARCHIVED_BY_CELL') }}
    </span>

    <span class="archive-list-head__cell">
      {{ $t('ARCHIVATION_DATE_CELL') }}
    </span>

    <span class="archive-list-head__cell">
      {{ $t('ARCHIVATION_REASON') }}
    </span>

    <span class="archive-list-head__cell">
      {{ $t('CASE_TIME_CELL') }}
    </span>

    <span class="archive-list-head__cell">
      {{ $t('LAST_STATE_CELL') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'archive-list-head',
}
</script>

<style lang="scss" scoped>
@import "../styles/archive.scss";

.archive-list-head {
  padding: 2em $list-padding-side 0;

  &__cell {
    @extend .archive-cmn__cell;

    line-height: 1.4;
    color: $color-dark-grey;
  }
}
</style>

<i18n>
{
  "en": {
    "USER_CELL": "USER",
    "ARCHIVED_BY_CELL": "ARCHIVED BY",
    "ARCHIVATION_DATE_CELL": "ARCHIVATION DATE",
    "ARCHIVATION_REASON": "REASON",
    "CASE_TIME_CELL": "CASE TIME",
    "LAST_STATE_CELL": "LAST STATE"
  },
  "ka": {
    "USER_CELL": "ᲛᲝᲛᲮᲛᲐᲠᲔᲑᲔᲚᲘ",
    "ARCHIVED_BY_CELL": "ᲓᲐᲐᲠᲥᲘᲕᲐ",
    "ARCHIVATION_DATE_CELL": "ᲓᲐᲐᲠᲥᲘᲕᲔᲑᲘᲡ ᲓᲠᲝ",
    "ARCHIVATION_REASON": "ᲛᲘᲖᲔᲖᲘ",
    "CASE_TIME_CELL": "ᲚᲘᲓᲘᲡ ᲮᲐᲜᲒᲠᲫᲚᲝᲕᲝᲑᲐ",
    "LAST_STATE_CELL": "ᲑᲝᲚᲝ ᲡᲢᲐᲢᲣᲡᲘ"
  },
  "ru": {
    "USER_CELL": "ПОЛЬЗОВАТЕЛЬ",
    "ARCHIVED_BY_CELL": "КЕМ АРХИВИРОВАН",
    "ARCHIVATION_DATE_CELL": "ДАТА АРХИВАЦИИ",
    "ARCHIVATION_REASON": "ПРИЧИНА",
    "CASE_TIME_CELL": "ВРЕМЯ КЕЙСА",
    "LAST_STATE_CELL": "ПОСЛЕДНЕЕ СОСТОЯНИЕ"
  },
  "uk": {
    "USER_CELL": "КОРИСТУВАЧ",
    "ARCHIVED_BY_CELL": "ХТО АРХІВУВАВ",
    "ARCHIVATION_DATE_CELL": "ДАТА АРХІВАЦІЇ",
    "ARCHIVATION_REASON": "ПРИЧИНА",
    "CASE_TIME_CELL": "ЧАС КЕЙСУ",
    "LAST_STATE_CELL": "ОСТАННІЙ СТАН"
  }
}
</i18n>
