<template>
  <div class="archive">
    <page-subnav class="archive__subnav">
      <archive-list-params
        class="archive__subnav-action"
        :value="{
          sort: $route.query.sort || '',
          minCreatedAt: $route.query.minCreatedAt || '',
          maxCreatedAt: $route.query.maxCreatedAt || '',
          channels: $route.query.channels || '',
          brokers: $route.query.brokers || '',
          location: $route.query.location || '',
          lastStates: $route.query.lastStates || '',
          isVerified: String($route.query.isVerified) === 'true',
          isUnverified: String($route.query.isUnverified) === 'true',
          isPrioritized: String($route.query.isPrioritized) === 'true',
          isFinanced: String($route.query.isFinanced) === 'true',
        }"
        @input="onListParamsChange"
      />
    </page-subnav>

    <archive-list
      class="archive__list"
      :group-by="groupBy"
      @update-list-ask="loadList"
    />
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import ArchiveList from './components/ArchiveList'
import ArchiveListParams from './components/ArchiveListParams'
import { archiveActions, archiveGetters } from './store/types'
import { mapActions, mapGetters } from 'vuex'
import { showError } from 'Utils/notifications'
import isEqual from 'lodash/isEqual'
import { ARCHIVE_SORTS, ARCHIVE_DEFAULT_SORT } from 'Constants/archiveUrlParams'
import { Archive } from 'Models/Archive'

export default {
  name: 'archive',

  components: {
    PageSubnav,
    ArchiveList,
    ArchiveListParams,
  },

  data () {
    return {
      groupBy: '',
    }
  },

  computed: {
    ...mapGetters('ui/archive', {
      list: archiveGetters.LIST,
    }),
  },

  created () {
    this.onListParamsChange(this.$route.query)
  },

  methods: {
    ...mapActions('ui/archive', {
      loadListAction: archiveActions.LOAD_LIST,
      setIsLoading: archiveActions.SET_IS_LOADING,
    }),

    onListParamsChange (input = {}) {
      const query = { ...this.$route.query }

      query.sort = input.sort || undefined
      query.minCreatedAt = input.minCreatedAt || undefined
      query.maxCreatedAt = input.maxCreatedAt || undefined
      query.channels = input.channels || undefined
      query.brokers = input.brokers || undefined
      query.location = input.location || undefined
      query.lastStates = input.lastStates || undefined
      query.isVerified = input.isVerified || undefined
      query.isUnverified = input.isUnverified || undefined
      query.isPrioritized = input.isPrioritized || undefined
      query.isFinanced = input.isFinanced || undefined

      if (!isEqual(query, this.$route.query)) {
        this.$router.push({ query })
      }

      this.loadList()
    },

    async loadList () {
      this.setIsLoading(true)

      try {
        const payload = this.buildLoadListPayload()
        await this.loadListAction(payload)
        this.afterLoad()
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
    },

    buildLoadListPayload () {
      const query = this.$route.query
      const payload = { filter: {} }

      payload.sort = query.sort || ARCHIVE_DEFAULT_SORT
      payload.filter.minCreatedAt = this.toUnixTs(query.minCreatedAt)
      payload.filter.maxCreatedAt = this.toUnixTs(query.maxCreatedAt)
      payload.filter.channels = query.channels
      payload.filter.brokers = query.brokers
      payload.filter.lastStates = query.lastStates
      payload.filter.isVerified =
        this.calcMutuallyExclusive(query.isVerified, query.isUnverified)
      payload.filter.priority = this.getHighPriorityIf(query.isPrioritized)
      payload.filter.financing = query.isFinanced
      payload.filter = {
        ...payload.filter,
        ...this.parseLocation(query.location),
      }

      return payload
    },

    toUnixTs (dateStr) {
      if (!dateStr) return
      return Math.trunc(new Date(dateStr).getTime() / 1000)
    },

    getHighPriorityIf (bool) {
      if (!bool) return
      return Archive.prioritiesEnum.high
    },

    parseLocation (str) {
      if (!str) return {}
      const [countryId, regionId, cityId] = str.split(':')
        .map(el => el === '0' ? '' : el)
      return {
        'location.country': countryId,
        'location.region': regionId,
        'location.city': cityId,
      }
    },

    calcMutuallyExclusive (boolA, boolB) {
      let res
      switch (true) {
        case boolA && !boolB: res = true; break
        case !boolA && boolB: res = false; break
        default: break
      }
      return res
    },

    afterLoad () {
      const query = this.$route.query

      const sort = query.sort || ARCHIVE_DEFAULT_SORT
      if (sort.includes(ARCHIVE_SORTS.createdAt)) {
        this.groupBy = 'createdAt'
      } else {
        this.groupBy = ''
      }
    }
  },

  metaInfo () {
    return {
      title: this.$t('META_TITLE'),
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style scoped lang="scss">
.archive {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>

<i18n>
{
  "en": {
    "META_TITLE": "Archive",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the archived leads list. Please try again later or contact the system owner."
  },
  "ka": {
    "META_TITLE": "არქივი",
    "LIST_FETCH_FAILED_NOTIFY": "დაარქივებული ლიდები ვერ ჩაიტვირთა, მიმართეთ ადმინისტრატორს."
  },
  "ru": {
    "META_TITLE": "Архив",
    "LIST_FETCH_FAILED_NOTIFY": "Не удается загрузить список архивированных лидов. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "META_TITLE": "Архів",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдається завантажити список архівованих лідів. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
