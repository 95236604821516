<template>
  <list-params
    class="archive-list-params"
    v-model="form"
    :initial-value="value"
    @form-apply="$emit('input', form)"
  >
    <template slot="form-rows">
      <list-params-sort
        class="archive-list-params__row"
        v-model="form.sort"
        :options="sortOptions"
        :default-value="defaultSort"
      />

      <list-params-period
        class="archive-list-params__row"
        :start-date.sync="form.minCreatedAt"
        :end-date.sync="form.maxCreatedAt"
      />

      <list-params-channels
        class="archive-list-params__row"
        v-model="form.channels"
      />

      <list-params-brokers
        class="archive-list-params__row"
        v-model="form.brokers"
      />

      <list-params-location
        class="archive-list-params__row"
        v-model="form.location"
      />

      <list-params-lead-states
        class="archive-list-params__row"
        v-model="form.lastStates"
        :label="$t('BY_LAST_STATE_LBL')"
      />

      <list-params-flags
        class="archive-list-params__row"
        :value="{
          isVerified: {
            label: $t('FLAG_VERIFIED_LBL'),
            value: form.isVerified,
          },
          isUnverified: {
            label: $t('FLAG_UNVERIFIED_LBL'),
            value: form.isUnverified,
          },
          isPrioritized: {
            label: $t('FLAG_PRIORITIZED_LBL'),
            value: form.isPrioritized,
          },
          isFinanced: {
            label: $t('FLAG_FINANCED_LBL'),
            value: form.isFinanced,
          },
        }"
        @input="form[$event.key] = $event.value"
      />
    </template>
  </list-params>
</template>

<script>
import {
  ARCHIVE_SORTS,
  ARCHIVE_DEFAULT_SORT,
} from 'Constants/archiveUrlParams'
import {
  ListParams,
  ListParamsPeriod,
  ListParamsSort,
  ListParamsChannels,
  ListParamsBrokers,
  ListParamsLocation,
  ListParamsLeadStates,
  ListParamsFlags,
} from 'Common/ListParams'

export default {
  name: 'archive-list-params',
  components: {
    ListParams,
    ListParamsPeriod,
    ListParamsSort,
    ListParamsChannels,
    ListParamsBrokers,
    ListParamsLocation,
    ListParamsLeadStates,
    ListParamsFlags,
  },

  props: {
    value: {
      type: Object,
      required: true,
      validator (value) {
        return typeof value.sort === 'string' &&
          typeof value.minCreatedAt === 'string' &&
          typeof value.maxCreatedAt === 'string' &&
          typeof value.channels === 'string' &&
          typeof value.brokers === 'string' &&
          typeof value.location === 'string' &&
          typeof value.lastStates === 'string' &&
          typeof value.isVerified === 'boolean' &&
          typeof value.isUnverified === 'boolean' &&
          typeof value.isPrioritized === 'boolean' &&
          typeof value.isFinanced === 'boolean'
      },
    },
  },

  data () {
    return {
      form: {
        sort: '',
        minCreatedAt: '',
        maxCreatedAt: '',
        channels: '',
        brokers: '',
        location: '',
        lastStates: '',
        isVerified: false,
        isUnverified: false,
        isPrioritized: false,
        isFinanced: false,
      },
    }
  },

  computed: {
    sortOptions () {
      return [
        {
          value: ARCHIVE_SORTS.createdAtDesc,
          label: this.$t('SORT_ARCHIVED_RECENTLY_FIRST_OPT'),
        },
        {
          value: ARCHIVE_SORTS.createdAt,
          label: this.$t('SORT_ARCHIVED_RECENTLY_LAST_OPT'),
        },
        {
          value: ARCHIVE_SORTS.caseDuration,
          label: this.$t('SORT_SHORT_CASE_FIRST_OPT'),
        },
        {
          value: ARCHIVE_SORTS.caseDurationDesc,
          label: this.$t('SORT_SHORT_CASE_LAST_OPT'),
        },
      ]
    },

    defaultSort () {
      return ARCHIVE_DEFAULT_SORT
    },
  },
}
</script>

<i18n>
{
  "en": {
    "SORT_ARCHIVED_RECENTLY_FIRST_OPT": "Archived recently first",
    "SORT_ARCHIVED_RECENTLY_LAST_OPT": "Archived recently last",
    "SORT_SHORT_CASE_FIRST_OPT": "Shortest cases first",
    "SORT_SHORT_CASE_LAST_OPT": "Longest cases first",
    "BY_LAST_STATE_LBL": "BY LAST STATE",
    "FLAG_VERIFIED_LBL": "Verified",
    "FLAG_UNVERIFIED_LBL": "Unverified",
    "FLAG_PRIORITIZED_LBL": "Prioritized",
    "FLAG_FINANCED_LBL": "Financed"
  },
  "ka": {
    "SORT_ARCHIVED_RECENTLY_FIRST_OPT": "ბოლოს დაარქივებულები",
    "SORT_ARCHIVED_RECENTLY_LAST_OPT": "ძველი დაარქივებული",
    "SORT_SHORT_CASE_FIRST_OPT": "ლიდის მოკლე ხანგრძლივობა",
    "SORT_SHORT_CASE_LAST_OPT": "ლიდის დიდი ხანგრძლივობა",
    "BY_LAST_STATE_LBL": "ᲑᲝᲚᲝ ᲡᲢᲐᲢᲣᲡᲘᲗ",
    "FLAG_VERIFIED_LBL": "მომხმარებელი",
    "FLAG_UNVERIFIED_LBL": "უცნობი",
    "FLAG_PRIORITIZED_LBL": "პრიორიტეტი",
    "FLAG_FINANCED_LBL": "დაფინანსება"
  },
  "ru": {
    "SORT_ARCHIVED_RECENTLY_FIRST_OPT": "Сначала недавно архивированные",
    "SORT_ARCHIVED_RECENTLY_LAST_OPT": "Сначала давно архивированные",
    "SORT_SHORT_CASE_FIRST_OPT": "Сначала короткие",
    "SORT_SHORT_CASE_LAST_OPT": "Сначала длинные",
    "BY_LAST_STATE_LBL": "ПО ПОСЛЕДНЕМУ СОСТОЯНИЮ",
    "FLAG_VERIFIED_LBL": "Пользователи",
    "FLAG_UNVERIFIED_LBL": "Гости",
    "FLAG_PRIORITIZED_LBL": "Приоритетные",
    "FLAG_FINANCED_LBL": "Финансированы"
  },
  "uk": {
    "SORT_ARCHIVED_RECENTLY_FIRST_OPT": "Спочатку нещодавно архівовані",
    "SORT_ARCHIVED_RECENTLY_LAST_OPT": "Спочатку давно архівовані",
    "SORT_SHORT_CASE_FIRST_OPT": "Спочатку короткі",
    "SORT_SHORT_CASE_LAST_OPT": "Спочатку довгі",
    "BY_LAST_STATE_LBL": "ЗА ОСТАННІМ СТАНОМ",
    "FLAG_VERIFIED_LBL": "Користувачі",
    "FLAG_UNVERIFIED_LBL": "Гості",
    "FLAG_PRIORITIZED_LBL": "Пріоритетні",
    "FLAG_FINANCED_LBL": "Фінансовані"
  }
}
</i18n>
