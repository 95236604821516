<template>
  <list-grouped
    class="archive-list"
    :list="list"
    :list-total-count="listCount"
    :group-by-date-field="groupBy"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :page-size="LIST_PAGE_LIMIT"
    :load-more="loadMore"
    :head-component="ArchiveListHead"
    :item-component="ArchiveItem"
    :item-skeleton-component="ArchiveItemSkeleton"
    @update-list-ask="$emit('update-list-ask')"
  />
</template>

<script>
import ArchiveItem from './ArchiveItem'
import ArchiveItemSkeleton from './skeletons/ArchiveItemSkeleton'
import ArchiveListHead from './ArchiveListHead'
import ListGrouped from 'Common/ListGrouped'
import { archiveActions, archiveGetters } from '../store/types'
import { mapGetters, mapActions } from 'vuex'

import { LIST_PAGE_LIMIT } from '../constants'

const GROUP_BY_ENUM = {
  createdAt: 'createdAt',
}

export default {
  name: 'archive-list',

  components: {
    ListGrouped,
  },

  props: {
    groupBy: {
      type: String,
      default: '',
      validator (value) {
        return !value || Object.values(GROUP_BY_ENUM).includes(value)
      },
    },
  },

  data () {
    return {
      ArchiveItem,
      ArchiveItemSkeleton,
      ArchiveListHead,
      LIST_PAGE_LIMIT,
    }
  },

  computed: {
    ...mapGetters('ui/archive', {
      list: archiveGetters.LIST,
      isLoading: archiveGetters.IS_LOADING,
      isNextLoading: archiveGetters.IS_NEXT_LOADING,
      listCount: archiveGetters.LIST_COUNT,
    }),
  },

  methods: {
    ...mapActions('ui/archive', {
      loadMore: archiveActions.LOAD_MORE,
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/archive.scss";

.archive-list {
  --list-padding-side: #{$list-padding-side};
}
</style>
