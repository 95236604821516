import { ModelBase } from './ModelBase'
import { Customer } from './Customer'
import { Identity } from './Identity'
import { Lead } from './Lead'

export class Archive extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)
    const r11s = raw.relationships || {}

    this.createdAt = this._date(raw.createdAt)
    this.caseDurationS = this._num(raw.caseDurationS)
    this.lastState = this._str(raw.lastState)
    this.leadId = this._str((r11s.lead || {}).id)
    this.priority = this._num(raw.priority)
    this.isPriorityHigh = this.priority >= Lead.prioritiesEnum.high
    this.reason = this._str(raw.reason)
    const customer = ModelBase.modelFromCache(Customer, r11s.customer, cache)
    this.fullName = customer.fullName
    this.avatarLink = customer.avatarLink

    const archivedBy = ModelBase.modelFromCache(
      Identity,
      r11s.archivedBy,
      cache
    )
    this.archivedByFullName = archivedBy.fullName
  }

  static get archivedReasonEnums () {
    return {
      manual: 'manual',
      won: 'won',
      noDeposit: 'no_deposit_too_long',
      identityDeleted: 'identity_deleted',
    }
  }

  static get lastStatesEnum () {
    return Lead.statesEnum
  }

  static get prioritiesEnum () {
    return Lead.prioritiesEnum
  }
}
